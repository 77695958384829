<template>
  <div class="analyticaccounting" v-if="hasPerm('payments.view_analyticaccount')">
    <page-header title="Comptabilité analytique" icon="fa fa-exchange-alt" :links="getLinks()"></page-header>
    <div class="sub-header">
      <b-row>
        <b-col cols="9">
          <date-range-selector :free-period="true" @change="onDateRangeChanged"></date-range-selector>
        </b-col>
        <b-col>
          <b-form-group
            id="only-school-year-group"
            label="Année scolaire"
            label-for="only-school-year"
          >
            <b-select v-model="onlySchoolYear" id="only-school-year" @change="loadElements()">
              <b-select-option v-for="schoolYear of schoolYears" :key="schoolYear.id" :value="schoolYear">
                {{ schoolYear.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
          <div class="text-right">
            <a href @click.prevent="showGroup = false" class="btn btn-xs btn-primary" v-if="showGroup">
              <i class="fa fa-plus"></i> de détails
            </a>
            <a href @click.prevent="showGroup = true" class="btn btn-xs btn-secondary" v-if="!showGroup">
              <i class="fa fa-minus"></i> de détails
            </a>
            &nbsp;
            <a href @click.prevent="loadElements()"><i class="fa fa-refresh"></i></a>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-input-group prepend="prepend">
            <b-input type="text" placeholder="Rechercher un analytique" v-model="analyticFilter"></b-input>
            <template #prepend>
              <span class="input-group-text"><i class="fa fa-search"></i></span>
            </template>
            <template #append>
              <b-button variant="outline-secondary" @click.prevent="analyticFilter = ''">
                <i class="fa fa-eraser"></i>
              </b-button>
            </template>
          </b-input-group>
        </b-col>
        <b-col cols="7">
          <div class="warning-text">
            Ce tableau est en cours de refonte. Son fonctionnement va être modifié et simplifié.
          </div>
        </b-col>
        <b-col cols="1">
          <b-checkbox id="showDelta" v-model="showDelta">delta</b-checkbox>
        </b-col>
        <b-col cols="1">
          <b-input type="number" class="small-input" placeholder="N°" v-model="entityNumber"></b-input>
          <div class="help-text">Une seule famille</div>
        </b-col>
      </b-row>
    </div>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div ref="printMe">
      <div class="hide-here">
        <h2>Comptabilité analytique du {{ startDate | dateToString }} au {{ endDate | dateToString }}</h2>
      </div>
      <div v-if="!isLoading(loadingName) && elements.length === 0">
        <b>Aucun élement</b>
      </div>
      <div ref="excelTable">
        <table class="table table-bordered" v-if="!isLoading(loadingName) && elements.length">
          <tr>
            <th></th>
            <th class="text-center sales" colspan="3">Ventes</th>
            <th class="text-center credits" :colspan="showUsedCredits ? 4 : 3">Avoirs</th>
            <th class="text-center payments" colspan="4">Paiements</th>
            <th v-if="showDelta" class="text-center delta" colspan="1"></th>
            <th class="text-center expenses" colspan="1"></th>
          </tr>
          <tr>
            <th>Analytique</th>
            <th class="text-center sales">À facturer</th>
            <th class="text-center sales">Facturées</th>
             <th class="text-center sales">dont Annulées</th>
            <th class="text-center credits">Générés</th>
            <th class="text-center credits">dont En cours</th>
            <th class="text-center credits" v-if="showUsedCredits">dont Utilisé</th>
            <th class="text-center credits">dont Remboursé</th>
            <th class="text-center payments">À payer</th>
            <th class="text-center payments">Numéraires encaissés</th>
            <th class="text-center payments">Numéraires en attente</th>
            <th class="text-center payments">Avoirs</th>
            <th v-if="showDelta" class="text-center delta">Delta</th>
            <th class="text-center expenses">Dépenses</th>
          </tr>
          <tr v-for="elt in elements" :key="elt.getKey()">
            <td class="line-header">
              <span :title="elt.analyticAccount.getLabel()">
                {{ elt.analyticAccount.name }} {{ elt.getYear() }}
                <i class="fa fa-list" title="Groupe" v-if="elt.analyticAccount.id < 0"></i>
              </span>
            </td>
            <td
              class="number sales" title="Ventes à facturer" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'no_invoice_amount')"
            >
              {{ elt.noInvoiceAmount | currency }}
            </td>
            <td
              class="number sales" title="Ventes facturées" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'sales_amount')"
            >
              {{ elt.amount | currency }}
            </td>
            <td
              class="number sales" title="Ventes annulées" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'cancelled_sales_amount')"
            >
              {{ elt.cancelledAmount | currency }}
            </td>
            <td
              class="number credits" title="Avoirs générés" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'cancellation_credits')"
            >
              {{ elt.getCreditOrigin() | currency }}
            </td>
            <td
              class="number credits" title="Avoirs en cours" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'remaining_credits')"
            >
              {{ elt.getAvailableCredits() | currency }}
            </td>
            <td
              v-if="showUsedCredits"
              class="number credits" title="Avoirs utilisés" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'used_credits')"
            >
              {{ elt.getUsedCredits() | currency }}
            </td>
            <td
              class="number credits" title="Avoirs remboursés" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'refund_credits')"
            >
              {{ elt.getRefund() | currency }}
            </td>
            <td
              class="number payments" title="À payer" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'to_be_paid_amount')"
            >
              {{ elt.getToBePaid() | currency }}
            </td>
            <td
              class="number payments" title="Numéraires encaissés" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'payment_contributions')"
            >
              {{ elt.getMoneyPayments() | currency }}
            </td>
            <td
              class="number payments" title="Numéraires en attente" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'future_payment_contributions')"
            >
              {{ elt.getFutureMoneyPayments() | currency }}
            </td>
            <td
              class="number payments" title="Avoirs" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'credit_contributions')"
            >
              {{ elt.getCreditPayments() | currency }}
            </td>
            <td
              v-if="showDelta" class="number delta" title="Delta" v-b-tooltip
            >
              {{ elt.delta() | currency }}
            </td>
            <td
              class="number expenses" title="Dépenses" v-b-tooltip
              @click.prevent="onShowDetail(elt, 'expenses_amount')"
            >
              {{ elt.expensesAmount | currency }}
            </td>
          </tr>
          <tr>
            <th></th>
            <th class="text-center sales">À facturer</th>
            <th class="text-center sales">Facturées</th>
             <th class="text-center sales">Annulées</th>
            <th class="text-center credits">Générés</th>
            <th class="text-center credits">En cours</th>
            <th class="text-center credits" v-if="showUsedCredits">Utilisé</th>
            <th class="text-center credits">Remboursé</th>
            <th class="text-center payments">À payer</th>
            <th class="text-center payments">Numéraires encaissés</th>
            <th class="text-center payments">Numéraires en attente</th>
            <th class="text-center payments">Avoirs</th>
            <th v-if="showDelta" class="text-center delta">Delta</th>
            <th class="text-center expenses">Dépenses</th>
          </tr>
          <tr>
            <th>Totaux</th>
            <th class="number sales">{{ sumNoInvoiceSales | currency }}</th>
            <th class="number sales">{{ sumSales | currency }}</th>
            <th class="number sales">{{ sumCancelledSales | currency }}</th>
            <th class="number credits">{{ sumCreditsOrigin | currency }}</th>
            <th class="number credits">{{ sumAvailableCredits | currency }}</th>
            <th class="number credits" v-if="showUsedCredits">{{ sumUsedCredits | currency }}</th>
            <th class="number credits">{{ sumRefund | currency }}</th>
            <th class="number payments">{{ sumToBePaid | currency }}</th>
            <th class="number payments">{{ sumPayments | currency }}</th>
            <th class="number payments">{{ sumFuturePayments | currency }}</th>
            <th class="number payments">{{ sumCredits | currency }}</th>
            <th v-if="showDelta" class="number delta">{{ sumDelta | currency }}</th>
            <th class="number expenses">{{ sumExpenses | currency }}</th>
          </tr>
        </table>
      </div>
      <analytic-accounting-detail-modal
        :start-date=startDate
        :end-date=endDate
        :field-name="selectedFieldName"
        :analytic-account="selectedAnalytic"
        :school-year="selectedSchoolYear"
        :entity-number="entityNumber"
        modal-id="bv-modal-analytic-accounting-detail"
      ></analytic-accounting-detail-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import AnalyticAccountingDetailModal from '@/components/Accounting/AnalyticAccountingDetailModal'
import LoadingGif from '@/components/Controls/LoadingGif'
import DateRangeSelector from '@/components/DateRange/DateRangeSelector'
import PageHeader from '@/components/Layout/PageHeader'
import { makeAnalyticDetail } from '@/types/payments'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, openDocument } from '@/utils/http'
import { sum } from '@/utils/math'

export default {
  name: 'AnalyticAccounting',
  mixins: [BackendMixin],
  components: {
    AnalyticAccountingDetailModal,
    DateRangeSelector,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      loadingName: 'AnalyticAccounting',
      allElements: [],
      dates: [],
      startDate: null,
      endDate: null,
      selectedFieldName: '',
      selectedAnalytic: null,
      selectedSchoolYear: null,
      schoolYears: [],
      onlySchoolYear: null,
      showGroup: false,
      analyticFilter: '',
      entityNumber: '',
      showUsedCredits: true, // désactive la colonne "Avoirs utilisés" pour l'instant
      showDelta: false,
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
  },
  computed: {
    elements() {
      let elements = []
      if (this.onlySchoolYear && this.onlySchoolYear.id) {
        elements = this.allElements.filter(
          elt => elt.schoolYear.id === this.onlySchoolYear.id
        )
      } else {
        elements = this.allElements
      }
      if (!this.showGroup) {
        if (this.analyticFilter) {
          const analyticValue = this.analyticFilter.toLowerCase()
          elements = elements.filter(
            itm => itm.analyticAccount.name.toLowerCase().indexOf(analyticValue) >= 0
          )
        }
        return elements
      } else {
        let groupedElements = []
        let groupMap = new Map()
        for (const element of elements) {
          if (element.analyticGroup.id) {
            const key = '' + element.analyticGroup.id + ':' + element.schoolYear.id
            if (groupMap.has(key)) {
              const index = groupMap.get(key)
              groupedElements[index].add(element)
            } else {
              const newIndex = groupedElements.length
              groupedElements.push(element.initGroup())
              groupMap.set(key, newIndex)
            }
          } else {
            groupedElements.push(element)
          }
        }
        if (this.analyticFilter) {
          const analyticValue = this.analyticFilter.toLowerCase()
          groupedElements = groupedElements.filter(
            itm => itm.analyticAccount.name.toLowerCase().indexOf(analyticValue) >= 0
          )
        }
        return groupedElements
      }
    },
    sumSales() {
      return sum(this.elements.map(elt => elt.amount))
    },
    sumCancelledSales() {
      return sum(this.elements.map(elt => elt.cancelledAmount))
    },
    sumToBePaid() {
      return sum(this.elements.map(elt => elt.getToBePaid()))
    },
    sumNoInvoiceSales() {
      return sum(this.elements.map(elt => elt.noInvoiceAmount))
    },
    sumPayments() {
      return sum(this.elements.map(elt => elt.getMoneyPayments()))
    },
    sumFuturePayments() {
      return sum(this.elements.map(elt => elt.getFutureMoneyPayments()))
    },
    sumCredits() {
      return sum(this.elements.map(elt => elt.getCreditPayments()))
    },
    sumCreditsOrigin() {
      return sum(this.elements.map(elt => elt.getCreditOrigin()))
    },
    sumAvailableCredits() {
      return sum(this.elements.map(elt => elt.getAvailableCredits()))
    },
    sumUsedCredits() {
      return sum(this.elements.map(elt => elt.getUsedCredits()))
    },
    sumRefund() {
      return sum(this.elements.map(elt => elt.getRefund()))
    },
    sumExpenses() {
      return sum(this.elements.map(elt => elt.expensesAmount))
    },
    sumDelta() {
      return sum(this.elements.map(elt => elt.delta()))
    },
  },
  mounted() {
    this.loadSchoolYears()
  },
  methods: {
    ...mapActions(['addError']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadSchoolYears() {
      let url = '/api/school-years/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schoolYears = [makeSchoolYear({ name: 'Toutes', })].concat(
          resp.data.map(elt => makeSchoolYear(elt))
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadElements() {
      this.allElements = []
      if (this.startDate && this.endDate) {
        this.startLoading(this.loadingName)
        let url = '/api/analytic-accounting/?start_date=' + this.startDate + '&end_date=' + this.endDate
        if (this.entityNumber) {
          url += '&entity=' + this.entityNumber
        }
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.allElements = resp.data.map(elt => makeAnalyticDetail(elt))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    onDateRangeChanged(event) {
      if ((this.startDate !== event.startDate) || (this.endDate !== event.endDate)) {
        this.startDate = event.startDate
        this.endDate = event.endDate
        this.loadElements()
      }
    },
    onShowDetail(elt, fieldName) {
      if (elt.analyticAccount.id >= 0) {
        this.selectedAnalytic = elt.analyticAccount
        this.selectedSchoolYear = elt.schoolYear
        this.selectedFieldName = fieldName
        if (this.startDate && this.endDate) {
          this.$bvModal.show('bv-modal-analytic-accounting-detail')
        }
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: this.isLoading(this.loadingName) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?landscape=1'
      const docSlug = 'analytic-accounting' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'analytic-accounting' + moment().format('YYYY-MM-DD-HH-MM-SS')
      const docContent = this.$refs.excelTable.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped lang="less">
.header-line {
  padding: 5px;
  background: #e0e0e0;
  border-bottom: none;
}
.header-label {
  font-size: 1.2em;
  font-weight: bold;
}
tr td .show-on-hover {
  display: none;
}
tr:hover td .show-on-hover {
  display: block;
  font-size: 11px;
}
th.number, td.number {
  text-align: right !important;
  width: 10%;
}

th {
  background: #444;
  color: #fff;
}

tr:hover td {
  background: #f8f8f8;
}

tr:nth-of-type(even) td {
  background: #e5e4e4;
}
tr:hover td.line-header {
  background: #555555;
  color: #fff;
}
tr:hover td.delta {
  background: #555555;
  color: #fff;
}

th.sales {
  background: #1b3158 !important;
  color: #fff !important;
}

td.sales {
  background: #bacae8;
  color: #000;
  cursor: pointer;
}

tr:nth-of-type(even) td.sales {
  background: #9badcb;
}
tr:hover td.sales {
  background: #1b3158;
  color: #fff;
}

th.payments {
  background: #3f3b3c;
  color: #fff;
}

td.payments {
  background: #b4a8aa;
  color: #000;
  cursor: pointer;
}

tr:nth-of-type(even) td.payments {
  background: #b7969c;
}
tr:hover td.payments {
  background: #3f3b3c;
  color: #fff;
}

th.credits {
  background: #4f3110;
  color: #fff;
}

td.credits {
  background: #eccdaa;
  color: #000;
  cursor: pointer;
}
tr:nth-of-type(even) td.credits {
  background: #eec08c;
}
tr:hover td.credits {
  background: #4f3110;
  color: #fff;
}

th.expenses {
  background: #a34452;
  color: #fff;
}
td.expenses {
  background: #e67a89;
  color: #000;
  cursor: pointer;
}
tr:nth-of-type(even) td.expenses {
  background: #e65d71;
}
tr:hover td.expenses {
  background: #a34452;
  color: #fff;
}
</style>
